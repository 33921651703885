.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.MuiButton-textSecondary {
  color: #026cb5 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* fixes bug when chart in dialog. potentially delete later */
.highcharts-tooltip-container {
  z-index: 1300 !important;
}

#range-timeSliderMap-container {
  box-sizing: border-box;
  flex-grow: 1;
  display: inline-block;
  position: relative;
  width: 70%;
  height: 100px;
  background-color: #ffffff;
  padding: 10px;
  border: 2px solid rgba(200,200,200,0.5);
}
#range-timeSliderMap-container > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#range-timeSliderMap-container #play-range {
  z-index: 1;
}
#range-timeSliderMap-container .slider {
  -webkit-appearance: none;
  appearance: none;
  background: rgba(255,255,255,0);
  outline: none;
  padding: 0;
  margin: 0px;
  cursor: pointer;
}
#range-timeSliderMap-container .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 3px;
  height: 96px;
  background: rgba(150,150,150,0.5);
  cursor: pointer;
}
#range-timeSliderMap-container .slider::-moz-range-thumb {
  border: none;
  width: 3px;
  height: 100px;
  background: rgba(150,150,150,0.5);
  cursor: pointer;
  border-radius: 0px;
}
#range-timeSliderMap-container .slider-disabled {
  cursor: auto;
}
#range-timeSliderMap-container .slider-disabled::-webkit-slider-thumb {
  background: rgba(150,150,150,0.5) !important;
  cursor: auto;
}
#range-timeSliderMap-container .slider-disabled::-moz-range-thumb {
  background: rgba(150,150,150,0.5) !important;
  cursor: auto;
}
#range-timeSliderMap-container .slider::-webkit-slider-thumb:hover,
#range-timeSliderMap-container .slider:focus::-webkit-slider-thumb {
  background: rgba(150,150,150,1);
}
#range-timeSliderMap-container .slider::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 6px 0;
  color: transparent;
}
#range-timeSliderMap-container .slider::-ms-fill-lower,
#range-timeSliderMap-container .slider::-ms-fill-upper,
#range-timeSliderMap-container .slider:focus::-ms-fill-lower,
#range-timeSliderMap-container .slider:focus::-ms-fill-upper {
  background: transparent;
}
#range-timeSliderMap-container .slider::-ms-thumb {
  border: none;
  width: 3px;
  height: 100px;
  background: rgba(150,150,150,0.5);
}
@media all and (max-width: 800px) {
  #range-timeSliderMap-container {
    width: 100%;
  }
}

table.simpletable {
  width: 100%;
  border: solid black 1px;
}
table.simpletable:nth-of-type(2) td {
  text-align: center;
}

table.ols-regression-comparison {
  border: solid black 1px;
  border-collapse: collapse;
}

table.ols-regression-comparison th,
table.ols-regression-comparison td {
  border-top: solid black 1px;
  border-bottom: solid black 1px;
}
table.ols-regression-comparison th,
table.ols-regression-comparison td {
  text-align: center;
  padding: 7px 14px;
}
table.ols-regression-comparison th:first-of-type,
table.ols-regression-comparison td:first-of-type {
  text-align: right;
  font-weight: bold;
  border-right: solid black 2px;
}
table.ols-regression-comparison tr:first-of-type th {
  border-bottom-width: 2px;
}

.mapIcon {
  white-space: nowrap;
  font-size: 0.6em;
  line-height: 1.1;
  color: #ffffff;
  background-color: rgba(2, 108, 181, 0.75);
  padding: 4px 5px;
  margin-right: 10px;
  text-align: center;
}

.mapIcon_inSelect {
  font-size: 0.5em;
}

.mapIcon_map {
  text-transform: uppercase;
  font-size: 1.2em;
  font-weight: bold;
}
